var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "100%", "background-color": "#FFFFFF" } },
    [
      _c("head-layout", {
        attrs: {
          "head-title": _vm.headTitle,
          "head-btn-options": _vm.headBtnOptions,
        },
        on: {
          "head-save": function ($event) {
            return _vm.headSave(false)
          },
          "head-saveBack": function ($event) {
            return _vm.headSave(true)
          },
          "head-preview": _vm.preview,
          "head-cancel": _vm.headCancel,
        },
      }),
      _c("div", { staticClass: "formContentBox" }, [
        _c(
          "div",
          { staticClass: "formMain" },
          [
            _c(
              "el-form",
              {
                ref: "dataForm",
                staticStyle: {
                  "margin-top": "12px",
                  width: "calc(100% - 5px)",
                },
                attrs: {
                  "label-width": "100px",
                  model: _vm.dataForm,
                  rules: _vm.rules,
                  disabled: _vm.formType == "view" || _vm.formType == "alter",
                },
              },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "模板编码", prop: "templateCode" },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "系统自动生成",
                                disabled: "",
                              },
                              model: {
                                value: _vm.dataForm.templateCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "templateCode", $$v)
                                },
                                expression: "dataForm.templateCode",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "模板名称", prop: "templateName" },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请输入模板名称",
                                maxlength: "30",
                              },
                              model: {
                                value: _vm.dataForm.templateName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "templateName", $$v)
                                },
                                expression: "dataForm.templateName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "状态", prop: "templateStatus" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "请选择状态",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.dataForm.templateStatus,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dataForm,
                                      "templateStatus",
                                      $$v
                                    )
                                  },
                                  expression: "dataForm.templateStatus",
                                },
                              },
                              _vm._l(_vm.publishStatusOption, function (item) {
                                return _c("el-option", {
                                  key: item.dictKey,
                                  attrs: {
                                    label: item.dictValue,
                                    value: item.dictKey,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "编制人", prop: "weaveUser" } },
                          [
                            _c("el-input", {
                              attrs: {
                                disabled: "",
                                placeholder: "系统自动生成",
                              },
                              model: {
                                value: _vm.dataForm.weaveUser,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "weaveUser", $$v)
                                },
                                expression: "dataForm.weaveUser",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "编制单位", prop: "weaveDept" } },
                          [
                            _c("el-input", {
                              attrs: {
                                disabled: "",
                                placeholder: "系统自动生成",
                              },
                              model: {
                                value: _vm.dataForm.weaveDept,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "weaveDept", $$v)
                                },
                                expression: "dataForm.weaveDept",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "使用级别",
                              prop: "applicableLevels",
                            },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.dataForm.applicableLevels,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dataForm,
                                      "applicableLevels",
                                      $$v
                                    )
                                  },
                                  expression: "dataForm.applicableLevels",
                                },
                              },
                              _vm._l(
                                _vm.usageLevelOption,
                                function (item, index) {
                                  return _c(
                                    "el-radio",
                                    {
                                      key: index,
                                      attrs: { label: item.dictKey },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(item.dictValue) +
                                          "\n                "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-row",
              {
                staticStyle: {
                  display: "flex",
                  "align-items": "center",
                  width: "100%",
                  "border-bottom": "1px solid #cccccc",
                  padding: "9px 0 8px 0",
                },
              },
              [
                _c(
                  "el-col",
                  {
                    staticStyle: {
                      float: "left",
                      display: "flex",
                      "align-items": "center",
                      height: "28px",
                    },
                    attrs: { span: "14" },
                  },
                  [
                    _c("div", { staticClass: "formTopic" }, [
                      _vm._v("模板信息"),
                    ]),
                  ]
                ),
                _c("el-col", { attrs: { span: "10" } }, [
                  _c(
                    "div",
                    { staticStyle: { float: "right" } },
                    [
                      _vm.formType != "view"
                        ? _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "button" },
                              on: { click: _vm.addTable },
                            },
                            [_vm._v("新增")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
            _c(
              "div",
              {
                staticStyle: {
                  "padding-top": "12px",
                  display: "flex",
                  "justify-content": "space-between",
                  height: "calc(100% - 172px)",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "leftClass" },
                  [
                    _vm.formType != "view"
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              type: "primary",
                              size: "medium",
                              icon: "el-icon-circle-plus-outline",
                            },
                            on: { click: _vm.addClass },
                          },
                          [_vm._v("添加分类\n          ")]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "classDataBox" },
                      _vm._l(_vm.classList, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "classLittle",
                            class:
                              _vm.classActive == index ? "classActive" : "",
                            on: {
                              click: function ($event) {
                                return _vm.chooseTableData(item, index)
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: { display: "flex", width: "100%" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "calc(100% - 45px)",
                                      "white-space": "normal",
                                      "text-overflow": "ellipsis",
                                      overflow: "hidden",
                                      "text-align": "right",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(item.groupName) +
                                        "\n                "
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "45px",
                                      "flex-shrink": "0",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " - " + _vm._s(item.columnNum) + "列"
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm.formType == "add" ||
                            _vm.formType == "edit" ||
                            (_vm.formType == "alter" && !item.id)
                              ? _c("i", {
                                  staticClass: "el-icon-edit",
                                  staticStyle: {
                                    "line-height": "unset",
                                    color: "#e6a23c",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.editClass(item, index)
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm.formType == "add" ||
                            _vm.formType == "edit" ||
                            (_vm.formType == "alter" && !item.id)
                              ? _c("i", {
                                  staticClass: "el-icon-delete",
                                  staticStyle: {
                                    "line-height": "unset",
                                    color: "#f56c6c",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.deleteClass(index)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]
                        )
                      }),
                      0
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "rightTable" },
                  [
                    _c(
                      "el-table",
                      {
                        ref: "table",
                        staticStyle: { width: "100%", height: "100%" },
                        attrs: {
                          data: _vm.properties,
                          border: "",
                          size: "medium",
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            prop: "date",
                            label: "排序",
                            align: "center",
                            type: "index",
                            width: "80",
                            fixed: "",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "fieldCode",
                            align: "center",
                            width: "160",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "header",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "justify-content": "center",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#F56C6C" } },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v("数据项编号\n                "),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    attrs: {
                                      disabled: true,
                                      clearable: "",
                                      maxlength: "25",
                                      placeholder: "请输入数据项编号",
                                    },
                                    model: {
                                      value: scope.row.fieldCode,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "fieldCode", $$v)
                                      },
                                      expression: "scope.row.fieldCode",
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "fieldName",
                            align: "center",
                            width: "160",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "header",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "justify-content": "center",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#F56C6C" } },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v("数据项名称\n                "),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    attrs: {
                                      disabled:
                                        _vm.formType == "view" ||
                                        (_vm.formType == "alter" &&
                                          scope.row.id),
                                      clearable: "",
                                      maxlength: "40",
                                      placeholder: "请输入数据项名称",
                                    },
                                    model: {
                                      value: scope.row.fieldName,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "fieldName", $$v)
                                      },
                                      expression: "scope.row.fieldName",
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "type",
                            align: "center",
                            width: "120",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "header",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "justify-content": "center",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#F56C6C" } },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v("类型\n                "),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        disabled:
                                          _vm.formType == "view" ||
                                          (_vm.formType == "alter" &&
                                            scope.row.id),
                                        placeholder: "请选择类型",
                                      },
                                      model: {
                                        value: scope.row.type,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "type", $$v)
                                        },
                                        expression: "scope.row.type",
                                      },
                                    },
                                    _vm._l(_vm.typeOptions, function (item) {
                                      return _c("el-option", {
                                        key: item.dictKey,
                                        attrs: {
                                          label: item.dictValue,
                                          value: item.dictKey,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "isEnable",
                            align: "center",
                            width: "120",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "header",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "justify-content": "center",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#F56C6C" } },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v("是否必填\n                "),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        disabled:
                                          _vm.formType == "view" ||
                                          (_vm.formType == "alter" &&
                                            scope.row.id),
                                        placeholder: "请选择是否必填",
                                      },
                                      model: {
                                        value: scope.row.isEnable,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "isEnable", $$v)
                                        },
                                        expression: "scope.row.isEnable",
                                      },
                                    },
                                    _vm._l(_vm.yesOrNoOptions, function (item) {
                                      return _c("el-option", {
                                        key: item.dictKey,
                                        attrs: {
                                          label: item.dictValue,
                                          value: item.dictKey,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "isSummary",
                            align: "center",
                            width: "120",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "header",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "justify-content": "center",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#F56C6C" } },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v("是否汇总\n                "),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        disabled:
                                          _vm.formType == "view" ||
                                          scope.row.type == "3" ||
                                          scope.row.type == "4" ||
                                          scope.row.type == "5" ||
                                          (_vm.formType == "alter" &&
                                            scope.row.id),
                                        placeholder: "请选择是否汇总",
                                      },
                                      model: {
                                        value: scope.row.isSummary,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "isSummary", $$v)
                                        },
                                        expression: "scope.row.isSummary",
                                      },
                                    },
                                    _vm._l(_vm.yesOrNoOptions, function (item) {
                                      return _c("el-option", {
                                        key: item.dictKey,
                                        attrs: {
                                          label: item.dictValue,
                                          value: item.dictKey,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "inputPrompt",
                            label: "输入提示",
                            align: "center",
                            width: "150",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    attrs: {
                                      clearable: "",
                                      maxlength: "200",
                                      disabled:
                                        _vm.formType == "view" ||
                                        (_vm.formType == "alter" &&
                                          scope.row.id),
                                      placeholder: "请输入输入提示",
                                    },
                                    model: {
                                      value: scope.row.inputPrompt,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "inputPrompt", $$v)
                                      },
                                      expression: "scope.row.inputPrompt",
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "inputLength",
                            label: "录入长度",
                            align: "center",
                            width: "120",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    attrs: {
                                      clearable: "",
                                      maxlength: "200",
                                      disabled:
                                        _vm.formType == "view" ||
                                        scope.row.type != "3" ||
                                        (_vm.formType == "alter" &&
                                          scope.row.id),
                                      placeholder: "请输入录入长度",
                                    },
                                    model: {
                                      value: scope.row.inputLength,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "inputLength", $$v)
                                      },
                                      expression: "scope.row.inputLength",
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "dictionaryCode",
                            label: "字典编码",
                            align: "center",
                            width: "120",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    attrs: {
                                      disabled:
                                        _vm.formType == "view" ||
                                        scope.row.type != "5" ||
                                        (_vm.formType == "alter" &&
                                          scope.row.id),
                                      clearable: "",
                                      maxlength: "40",
                                      placeholder: "请输入字典编码",
                                    },
                                    model: {
                                      value: scope.row.dictionaryCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          scope.row,
                                          "dictionaryCode",
                                          $$v
                                        )
                                      },
                                      expression: "scope.row.dictionaryCode",
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "inputWay",
                            align: "center",
                            width: "120",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "header",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "justify-content": "center",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#F56C6C" } },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v("输入方式\n                "),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        disabled:
                                          _vm.formType == "view" ||
                                          scope.row.type == "3" ||
                                          scope.row.type == "4" ||
                                          scope.row.type == "5" ||
                                          (_vm.formType == "alter" &&
                                            scope.row.id),
                                        placeholder: "请选择输入方式",
                                      },
                                      model: {
                                        value: scope.row.inputWay,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "inputWay", $$v)
                                        },
                                        expression: "scope.row.inputWay",
                                      },
                                    },
                                    _vm._l(
                                      _vm.inputWayOptions,
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.dictKey,
                                          attrs: {
                                            label: item.dictValue,
                                            value: item.dictKey,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "inputWay",
                            align: "center",
                            width: "120",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "header",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "justify-content": "center",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  单位\n                "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        disabled:
                                          _vm.formType == "view" ||
                                          scope.row.type == "1" ||
                                          scope.row.type == "2" ||
                                          (_vm.formType == "alter" &&
                                            scope.row.id),
                                        placeholder: "请选择单位",
                                      },
                                      model: {
                                        value: scope.row.unit,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "unit", $$v)
                                        },
                                        expression: "scope.row.unit",
                                      },
                                    },
                                    _vm._l(_vm.unit, function (item) {
                                      return _c("el-option", {
                                        key: item.dictKey,
                                        attrs: {
                                          label: item.dictValue,
                                          value: item.dictKey,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "calculationRules",
                            label: "计算公式",
                            align: "center",
                            width: "350",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    attrs: {
                                      disabled:
                                        _vm.formType == "view" ||
                                        scope.row.type == "3" ||
                                        scope.row.type == "4" ||
                                        scope.row.type == "5" ||
                                        (_vm.formType == "alter" &&
                                          scope.row.id) ||
                                        scope.row.inputWay == "1",
                                      clearable: "",
                                      maxlength: "40",
                                      placeholder: "示例:#{a}+#{b}",
                                    },
                                    model: {
                                      value: scope.row.calculationRules,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          scope.row,
                                          "calculationRules",
                                          $$v
                                        )
                                      },
                                      expression: "scope.row.calculationRules",
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm.formType != "view"
                          ? _c("el-table-column", {
                              attrs: {
                                label: "操作",
                                align: "center",
                                width: "150",
                                fixed: "right",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        (_vm.formType == "edit" &&
                                          scope.row.isStatus == "0") ||
                                        (_vm.formType == "add" &&
                                          scope.row.isStatus == "0") ||
                                        (_vm.formType == "alter" &&
                                          !scope.row.id)
                                          ? _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.displayTable(
                                                      scope.row,
                                                      scope.$index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("显示\n                ")]
                                            )
                                          : _vm._e(),
                                        (_vm.formType == "add" &&
                                          scope.row.isStatus == "1") ||
                                        (_vm.formType == "edit" &&
                                          scope.row.isStatus == "1") ||
                                        (_vm.formType == "alter" &&
                                          !scope.row.id)
                                          ? _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.hideTable(
                                                      scope.row,
                                                      scope.$index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("隐藏\n                ")]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.addTable(
                                                  scope.row,
                                                  scope.$index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("新增\n                ")]
                                        ),
                                        _vm.formType == "add" ||
                                        _vm.formType == "edit" ||
                                        (_vm.formType == "alter" &&
                                          !scope.row.id)
                                          ? _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deleteTable(
                                                      scope.row,
                                                      scope.$index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("删除\n                ")]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                465628808
                              ),
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm.classShow
        ? _c(
            "CommonDialog",
            {
              attrs: { width: "30%", dialogTitle: _vm.classTitle },
              on: {
                cancel: function ($event) {
                  _vm.classShow = false
                },
                confirm: _vm.submitClass,
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "classForm",
                  attrs: {
                    model: _vm.classForm,
                    "label-width": "80px",
                    rules: _vm.classRules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "分类名称", prop: "groupName" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入分类名称",
                          maxlength: "50",
                        },
                        model: {
                          value: _vm.classForm.groupName,
                          callback: function ($$v) {
                            _vm.$set(_vm.classForm, "groupName", $$v)
                          },
                          expression: "classForm.groupName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "分类列数", prop: "columnNum" } },
                    [
                      [
                        _c("el-input-number", {
                          attrs: { min: 1, max: 4, label: "描述文字" },
                          on: { change: _vm.handleChange },
                          model: {
                            value: _vm.classForm.columnNum,
                            callback: function ($$v) {
                              _vm.$set(_vm.classForm, "columnNum", $$v)
                            },
                            expression: "classForm.columnNum",
                          },
                        }),
                      ],
                    ],
                    2
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否显隐", prop: "isStatus" } },
                    [
                      [
                        _c("el-switch", {
                          attrs: {
                            "active-text": "显示",
                            "inactive-text": "隐藏",
                          },
                          model: {
                            value: _vm.classForm.isStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.classForm, "isStatus", $$v)
                            },
                            expression: "classForm.isStatus",
                          },
                        }),
                      ],
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.previewShow
        ? _c(
            "CommonDialog",
            {
              attrs: { showBtn: false, width: "50%", dialogTitle: "预览" },
              on: { cancel: _vm.handleClose },
            },
            [_c("preview", { ref: "preview" })],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }